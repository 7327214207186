/*!
Theme Name: JCWD Starter Theme
Author: John Carter
Author URI: http://www.johncarter.co.uk/
*/

@import url('https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700|Open+Sans:300,400,500,600,700');

// bootstrap variables
$body-bg: #fafafa;
$font-family-sans-serif: "Open Sans";
$font-weight-base: 400;
$headings-font-family: 'Rajdhani';
$theme-colors: (
	primary: #ffc62e,
	light-blue: #e5eef7,
	light-orange: #fff5e2,
	green: #65c739,
);
$container-max-widths: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1320px
);
// bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

body {
	padding-top: 126px !important;
}

p a,
li a {
	color: #111;
	border-width: 2px 0;
    border-bottom-color: map-get( $theme-colors, primary);
    border-style: solid;
    border-top-color: transparent;
    &:hover {
    	text-decoration: none;
    }
}

a.link-plain {
	border: 0 !important; 
	text-decoration: none !important;
}

.btn-amazon {
	border-radius: 3px;
	border-color: #9c7e31 #90742d #786025 !important;
	border-width: 1px;
	border-style: solid;
	color: #111;
	padding: 7px 12px 6px 12px;
	line-height: 19px;
	font-weight: 400;
	font-size: 14px;
	background: linear-gradient(to bottom, #f7dfa5, #f0c14b) repeat scroll 0 0 rgba(0, 0, 0, 0)
}

.btn-amazon:before {
	font-family: 'FontAwesome';
	content: "\f270";
	color: #333;
	font-size: 16px;
	vertical-align: middle;
	margin-right: 5px;
}

.btn-amazon:hover {
	color: #111;
	border-color: #9c7e31 #90742d #786025 !important;
	text-decoration: none;
}

.display-5 {
	font-size: 2.5rem;
}

.carousel-item {
	max-height: 370px;
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
	.carousel-caption {
		background: rgba(0, 0, 0, 0.5);
	}
	@include media-breakpoint-down(md) {
		.display-4 {
			font-size: 2.5rem;
		}
		height: 500px;
		max-height: none;
	}
}

.border-thick {
    border-width:3px !important;
}

.border-thickest {
    border-width:7px !important;
}


.navbar-brand {
	max-width: 400px;
	@include media-breakpoint-down(xs) {
		max-width: 300px;
	}
}


.navbar-nav {
	.nav-item {
		&.current-post-ancestor .nav-link,
		&:hover .nav-link {
			background: map-get( $theme-colors, primary);
			color: #fff;
		}
		.nav-link {
			color: #111;
			text-transform: uppercase;
			font-size: 0.9rem;
			font-weight: 400;
			margin-left: 1rem;
		}
	}
	
}

.navbar-collapse {
	padding: 1em 0;
	.nav-item {
		.nav-link {
			margin: 0;
			padding: .5rem 1rem;
			border: 0;
		}
	}
}

.post-grid {
	.card:hover {
		opacity: 0.92;
	}
	.post-meta {
		color: $gray-400
	}
}


.single-post-header {
	height: 300px;
	img {
		object-fit: cover;
		object-position: center;
		height: 100%;
		width: 100%;
	}
}


.product-row {
	position: relative;
}

.product-ribbon {
	position: absolute;
	top: 1rem;
	right: -2rem;
	padding: 0.5rem 1.5rem;
	background-color: map-get( $grays, '700');
	p {
		margin: 0;
		padding: 0;
		font-weight: 500;
		font-family: $headings-font-family;
		font-size: 1.25rem;
		color: #fff;
	}
}